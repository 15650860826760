import React, { FunctionComponent } from "react";
import { ImageInterface, SanityCtaBlock, VideoInterface } from "../../types/SanityTypes";
import SimpleBanner from "../SimpleBanner";
import Section from "../Section";
import "./styles.scss";

interface SimpleBannerListingInterface {
  name?: string;
  banners: {
    heading?: string;
    subHeading?: string;
    heroLabel?: string;
    contentAlignment?: string;
    isVideoBanner?: boolean;
    simpleBannerImage?: ImageInterface;
    _rawSimpleBannerImage?: ImageInterface;
    _rawSimpleBannerVideo?: VideoInterface;
    ctaLink?: SanityCtaBlock;
    primaryColor?: {
      title: string;
      value: string;
    };
  }[];
}

const SimpleBannerListing: FunctionComponent<SimpleBannerListingInterface> = ({ name, banners }) => {
  const columnsClassName = banners.length > 2 ? "grid-col-md-4" : "grid-col-md-6";
  return (
    <Section sectionClassName="simple-banner-listing">
      <div className="simple-banner-listing__list">
        {banners.map((banner, index) => (
          <div key={index} className={`simple-banner-listing__list-item ${columnsClassName}`}>
            <SimpleBanner
              heading={banner?.heading}
              subHeading={banner?.subHeading}
              heroLabel={banner?.heroLabel}
              contentAlignment={banner?.contentAlignment}
              isVideoBanner={banner?.isVideoBanner}
              simpleBannerImage={banner?.simpleBannerImage}
              _rawSimpleBannerVideo={banner?._rawSimpleBannerVideo}
              ctaLink={banner?.ctaLink}
              primaryColor={banner?.primaryColor}
            />
          </div>
        ))}
      </div>
    </Section>
  );
};

export default SimpleBannerListing;
